import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import TOC from "components/toc";
import SaleLoadable from "components/saleLoadable";
import Socials from "components/socials";
import AccentBox from "components/accentBox";
import { graphql } from "gatsby";
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "wireless"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`キーボード・マウスは無線が良いか有線が良いか`}</h1>
    <Socials {...props} mdxType="Socials" />
    <p>{`パソコンにはコードが異常に多い、本体以外にもスピーカーとモニタの電源、モニタとパソコン本体を繋ぐ線、キーボード、マウス、インターネット接続のLAN、更には外付けのハードディスクなどもあり机中がコードまみれになるケースもある。`}</p>
    <p>{`このような場合どのコードが何かを識別するのは面倒であるし、コードが絡まってしまうと更に不快になってしまう。なのでコード数はできるだけ少なくなるように努力したいものである。よって個人的にはワイヤレスをおすすめしたい。`}</p>
    <p>{`と、昔はそう思っていたが、無線LANを暫く使用していると電池が切れて買い置きがなく店まで買いに行ったり、原因が良くわからずに反応が鈍くなったりしたため有線の良さも見直しつつあるこの頃である。`}</p>
    <p>{`無駄なコードを無くして周りをスッキリとさせたいか、電池切れや動作が不安定になる不安を有線にすることにより取り除きたいか、すなわち`}<strong parentName="p">{`見た目を買うか、安心を買うかという難しい問い`}</strong>{`であり、管理人も有線を使ったり無線を使ったりと浮ついた状態である。`}</p>
    <p>{`ともあれメリットとデメリットを整理し、状況にあった判断ができるようにしたい。`}</p>
    <SaleLoadable title="現在のパソコンセール・お買い得情報" mdxType="SaleLoadable" /><br />
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "有線・無線マウス・キーボードそれぞれのメリット",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%9C%89%E7%B7%9A%E3%83%BB%E7%84%A1%E7%B7%9A%E3%83%9E%E3%82%A6%E3%82%B9%E3%83%BB%E3%82%AD%E3%83%BC%E3%83%9C%E3%83%BC%E3%83%89%E3%81%9D%E3%82%8C%E3%81%9E%E3%82%8C%E3%81%AE%E3%83%A1%E3%83%AA%E3%83%83%E3%83%88",
        "aria-label": "有線・無線マウス・キーボードそれぞれのメリット permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`有線・無線マウス・キーボードそれぞれのメリット`}</h2>
    <p>{`まずは有線マウス・無線マウスのメリットや特徴を挙げると次のものがある。`}</p>
    <AccentBox title="有線マウスのメリット" mdxType="AccentBox">
  <li>USBから給電されるため、電池切れを心配しなくても良い</li>
  <li>同品質のものならば価格が安い</li>
  <li>誤作動や遅延が起こらず、動作が安定する</li>
  <li>作りが簡単なため故障率が低い</li>
    </AccentBox>
    <AccentBox title="無線マウスのメリット" mdxType="AccentBox">
  <li>無駄なコードが無く、配線をスッキリさせることができる</li>
  <li>遠隔操作が可能</li>
  <li>マウスが若干ではあるが動かしやすくなる</li>
    </AccentBox>
    <p>{`無線で動作が安定しないかと言われると十分安定している場合が大半であるが、電池の減少や無線の環境、デスクトップパソコンとマウスの距離次第では遅延や反応しない状況が起こり得る。`}</p>
    <p>{`マウス・キーボードが使う無線の多くは2.4GHz帯域とうい無線の帯域を使用しており、例えば電子レンジの発する電波と干渉するため、電子レンジ起動中だけ動作が不安定になるということが起こる。`}</p>
    <p>{`他の無線機器と干渉する可能性もあり、`}<strong parentName="p">{`多くの電波が飛び交う街中や無線電波が飛び交っているような職場では不安定になる可能性は特に大きい`}</strong>{`。`}</p>
    <p>{`電池に関して言えば、マウスで最低半年、キーボードで1年程度は持続するため過度な心配をする必要は無さそうである。`}</p>
    <h2 {...{
      "id": "敢えて有線を選ぶケース",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%95%A2%E3%81%88%E3%81%A6%E6%9C%89%E7%B7%9A%E3%82%92%E9%81%B8%E3%81%B6%E3%82%B1%E3%83%BC%E3%82%B9",
        "aria-label": "敢えて有線を選ぶケース permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`敢えて有線を選ぶケース`}</h2>
    <p>{`現状無線のマウスが売れ筋ではあるが、敢えて有線を選択するケースを考えてみる。`}</p>
    <h3 {...{
      "id": "コードが鬱陶しいと感じなければ有線",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B3%E3%83%BC%E3%83%89%E3%81%8C%E9%AC%B1%E9%99%B6%E3%81%97%E3%81%84%E3%81%A8%E6%84%9F%E3%81%98%E3%81%AA%E3%81%91%E3%82%8C%E3%81%B0%E6%9C%89%E7%B7%9A",
        "aria-label": "コードが鬱陶しいと感じなければ有線 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`コードが鬱陶しいと感じなければ有線`}</h3>
    <p><strong parentName="p">{`コードがあることを鬱陶しいと感じなければわざわざ動作が不安定で、電池代もかかる無線を選ぶ理由はない`}</strong>{`。無線に変えることによって逆にストレスを感じることにもなりかねない。`}</p>
    <p>{`逆にリビングでパソコンを使用しており、インテリアを重視して余分なコードを少しでも見せたくないような場合はワイヤレスにせざるを得ない。`}</p>
    <h3 {...{
      "id": "たまの外出で持ち歩くケース",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%81%9F%E3%81%BE%E3%81%AE%E5%A4%96%E5%87%BA%E3%81%A7%E6%8C%81%E3%81%A1%E6%AD%A9%E3%81%8F%E3%82%B1%E3%83%BC%E3%82%B9",
        "aria-label": "たまの外出で持ち歩くケース permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`たまの外出で持ち歩くケース`}</h3>
    <p>{`あまり電池が切れなくとも、たまにノートパソコンとマウスを持ち外出した時に電池が切れていた事が何度かあったため、たまの持ち歩き用に無線マウスを使用するのは危険である。`}</p>
    <p>{`もっとも、切れていたらノートパッドを使えば良いだけなのでそれほど大きな問題は起きないが。ともあれマウスの方が作業が捗るので`}<strong parentName="p">{`出張など使用機会が多い場合は小型の有線マウスを持っていく`}</strong>{`ようにしている。`}</p>
    <h3 {...{
      "id": "高級キーボードを使いたいケース",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E9%AB%98%E7%B4%9A%E3%82%AD%E3%83%BC%E3%83%9C%E3%83%BC%E3%83%89%E3%82%92%E4%BD%BF%E3%81%84%E3%81%9F%E3%81%84%E3%82%B1%E3%83%BC%E3%82%B9",
        "aria-label": "高級キーボードを使いたいケース permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`高級キーボードを使いたいケース`}</h3>
    <p>{`多くの高級キーボードは有線での接続である。これは高速なタイピングをしても動作が追随できるようシビアな要求をこなすためには有線にせざるを得ない事情があると言えると思うが、ともあれ`}<strong parentName="p">{`高級キーボードで無線はほとんど無い`}</strong>{`状況である。`}</p>
    <p>{`有線マウスはマウスの動きをやや制限してしまう面があるが、有線キーボードでは位置固定のために制限されることはないため、快適性をとり、無線マウス、有線キーボードの組み合わせも一つの手である。`}</p>
    <h2 {...{
      "id": "敢えて無線を選ぶケース",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E6%95%A2%E3%81%88%E3%81%A6%E7%84%A1%E7%B7%9A%E3%82%92%E9%81%B8%E3%81%B6%E3%82%B1%E3%83%BC%E3%82%B9",
        "aria-label": "敢えて無線を選ぶケース permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`敢えて無線を選ぶケース`}</h2>
    <p>{`積極的に無線を選ぶケースは、遠隔操作がしたいかどうかである。YoutubeやAmazon Prime Videoを離れてみたい、検索したい場合には無線一択である。`}</p>
    <h2 {...{
      "id": "迷った時の実践的な決め方",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E8%BF%B7%E3%81%A3%E3%81%9F%E6%99%82%E3%81%AE%E5%AE%9F%E8%B7%B5%E7%9A%84%E3%81%AA%E6%B1%BA%E3%82%81%E6%96%B9",
        "aria-label": "迷った時の実践的な決め方 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`迷った時の実践的な決め方`}</h2>
    <p>{`上記のように自分の中に確固たる意志がある、明確な理由が見いだせる、それ以外に選択肢がないという場合には気に入った形のものを買うというのが良いと思う。`}</p>
    <p>{`無線か有線かは一長一短なのでそれ以外の要素で決めようということである。参考になったか分からないが。。。`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      